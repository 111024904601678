<template>
	<SideOver isLarge :title="title" :is-show="isShow" @onClose="() => onClose()" :label="content" :isLoading="isLoading"
						:buttons="getButtons()">
		<div class="flex-1 flex-col p-6 space-y-4 overflow-y-auto">
			<div v-for="form in forms" :key="form.id" class="space-y-4">
				<Text size="xl" weight="semibold" color="black" :content="form.name"/>
				<div class="grid gap-x-3 gap-y-4" :class="`grid-cols-${form.list.cols}`">
					<div :class="`col-span-${field.cols}`" v-for="field in form.list.fields" :key="field.id">
						<AutoComplete v-if="field.inputType === 'AUTOCOMPLETE'"
													:label="`${!field.isLeft && !field.groupFields && field.label ? field.label : ''}`"
													:listData="JSON.parse(field.options)"
													:placeholder="`${field.placeholder ? field.placeholder : 'Start typing to filter'}`"
													:handleSelectFn="(val) => {formObject[field.modelField]= val;}"/>
						<Select
								v-if="field.inputType === 'DROPDOWN'"
								:placeholder="`${field.placeholder ?field.placeholder : 'Please select a value'}`"
								:label="`${!field.isLeft && !field.groupFields && field.label ? field.label : ''}`"
								:items="JSON.parse(field.options)"
								v-model:selected-item="formObject[field.modelField]"
								custom-class="w-full"
								:help-text="field.helpText"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-if="formObject.targetCustomer" class="bg-primary-700 px-6 py-4 w-full">
			<Text size="sm" weight="semibold" color="white" content="Does this Opportunity Statement sound right?"/>
			<Text size="xs" weight="" color="white" v-html="getHtml()" custom-class="mt-2"/>
		</div>
	</SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'
import AutoComplete from '../../../molecules/AutoComplete/AutoComplete'
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import Simple from '../../../organisms/Tables/Simple/Simple.vue';

export default {
	components: {
		Text,
		Icon,
		Input,
		Select,
		Button,
		SideOver,
		Textarea,
		AutoComplete,
		MultiSelectDropdown,
		Simple
	},
	props: {
		isShow: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			default: true
		},
		isSaving: {
			type: Boolean,
			default: false
		},
		forms: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		getHtml() {
			return `${this.formObject.targetCustomer?.name} need to keep track of their exercises at home, ${this.formObject.problem?.name ? `but it's too difficult and too confusing because they are written on easy-to-lose papers.` : ''}`
		},
		onClose() {
			this.$emit('onClose');
		},
		getButtons() {
			return [
				{
					label: 'Back',
					type: 'secondary',
					isLoading: false,
					action: () => this.onBack()
				},
				{
					label: this.activeIndex !== 5 ? 'Next' : 'Save',
					type: 'primary',
					isLoading: this.isSaving,
					action: () => this.onSave()
				}
			]
		},
		onSave() {

		}
	},
	data() {
		return {
			show: false,
			formObject: {customerType: ''},
		}
	},

	mounted() {
		setTimeout(() => {
			this.show = this.isShow;
		}, 50);
	},
}
</script>
<style scoped>
</style>
