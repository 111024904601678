<template>
    <Template
        v-bind="{
            ...c.ecosystemOpportunities,
            items: opportunityList,
            toggleSlideover: () => (isShow = true),
            onClickItem: (item) =>
                $router.push({
                    name: 'Venture Opportunity Detail',
                    params: { ventureId: item?.ventureId, id: item?.id },
                }),
            emptyViewBinding: emptyViewBinding,
            searchText: searchText,
            onSearchTextChange: (text) => onSearchTextChange(text),
        }"
    >
    </Template>
</template>

<script>
import Template from "../components/templates/EcosystemOpportunities/EcosystemOpportunities";
import { c } from "../components/constants.js";
import AddOpportunity from "@/components/organisms/Modals/AddOpportunity/AddOpportunity";
import numeral from "numeral";

export default {
    components: {
        AddOpportunity,
        Template,
    },
    data() {
        return {
            c,
            opportunities: [],
            isShow: false,
            emptyViewBinding: {
                description: "No opportunities to display just yet!",
            },
            searchText: "",
        };
    },
    async created() {
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: "companyDefault",
            });
            console.log(companies);
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
            console.log(this.company, "company");
        }
        const userSurveys = await this.actions.userSurvey.getUserSurveys({
            where: {
                isArchived_not: true,
            },
            getValues: true,
            all: true,
            fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
        });
        this.company?.programs?.map((p) =>
            p?.cohorts?.map((c) =>
                c?.ventures?.map((v) => {
                    v?.opportunities
                        ?.filter(
                            (o) =>
                                !o?.isArchived &&
                                o?.surveyOpportunities?.find(
                                    (s) => s?.responses?.length > 0
                                )
                        )
                        ?.map((op) => {
                            let responses = 0;
                            op?.surveyOpportunities?.map(
                                (so) =>
                                    (responses += so?.responses?.length || 0)
                            );
                            this.opportunities?.push({
                                Opportunity: op?.name
                                    ?.split(" ")?.[0]
                                    ?.includes("#")
                                    ? op?.name
                                          ?.split(" ")
                                          ?.splice(
                                              1,
                                              op?.name?.split(" ")?.length
                                          )
                                          ?.join(" ")
                                    : op?.name,
                                Venture: v?.name,
                                Responses: userSurveys?.filter(
                                    (u) =>
                                        u?.opportunity?.id === op?.id
                                )?.length,
                                Magnitude: {
                                    out: op?.outOfMarketPercentage || 0,
                                    low: op?.lowMarketPercentage || 0,
                                    mid: op?.midMarketPercentage || 0,
                                    hi: op?.hiMarketPercentage || 0,
                                    magnitude: op?.magnitudeScore || 0,
                                },
                                Market: numeral(op?.adjustedMarket).format(
                                    "0,0"
                                ),
                                id: op.id,
                                ventureId: v?.id,
                            });
                        });
                })
            )
        );
    },
    computed: {
        opportunityList() {
            const opportunities = [...this.opportunities];
            if (this.searchText.trim()) {
                return opportunities
                    .sort((a, b) =>
                        a?.Magnitude?.magnitude > b?.Magnitude?.magnitude
                            ? -1
                            : 1
                    )
                    ?.filter((item) =>
                        item?.Opportunity.toLowerCase().includes(
                            this.searchText.trim().toLowerCase()
                        )
                    );
            }
            return opportunities;
        },
    },
    methods: {
        onSearchTextChange(searchText) {
            this.searchText = searchText;
        },
    },
};
</script>

<style scoped></style>
